import React from 'react';
import { Container, Stack } from '@tymate/margaret';
import { graphql } from 'gatsby';
import { Button, Hero, SEO, Layout, AppointmentSection } from 'components';
import LawyerCarousel from 'components/LawyerCarousel';
import { Section, Title, Content, SectionImage } from 'ui';

const IndexPage = ({ data }) => (
  <Layout variant="homepage">
    <SEO
      title={data?.datoCmsHomepage?.seo?.title}
      description={data?.datoCmsHomepage?.seo?.description}
    />
    <Hero data={data} />
    <Section>
      <Container>
        <Stack
          gutterSize={2}
          alignY="center"
          direction={{ default: 'column', tablet: 'row' }}
        >
          <div>
            <Title>
              {data?.datoCmsHomepage?.lawFirmTitle}{' '}
              <span>{data?.datoCmsHomepage?.lawFirmSubtitle}</span>
            </Title>
            <Content>{data?.datoCmsHomepage?.lawFirmContent}</Content>
            <Stack marginTop={2}>
              <Button
                variant="outline"
                to="/cabinet"
                state={{ defaultIndex: 2 }}
              >
                Nos engagements
              </Button>
            </Stack>
          </div>
          <SectionImage>
            <img src={data?.datoCmsHomepage?.lawFirmImage?.url} alt="" />
          </SectionImage>
        </Stack>
      </Container>
    </Section>
    <Section background="light">
      <Container>
        <Title>{data?.datoCmsHomepage?.teamTitle}</Title>

        <LawyerCarousel data={data?.allDatoCmsLawyer?.edges} />

        <Stack alignX="center" marginTop={2}>
          <Button variant="outline" to="/cabinet" state={{ defaultIndex: 1 }}>
            Mieux les connaître
          </Button>
        </Stack>
      </Container>
    </Section>
    <AppointmentSection />
  </Layout>
);

export const query = graphql`
  query {
    datoCmsHomepage {
      mainTitle
      description
      mainImage {
        alt
        url
      }
      lawFirmTitle
      lawFirmSubtitle
      lawFirmContent
      lawFirmImage {
        url
        alt
      }
      teamTitle
      appointmentTitle
      appointmentImage {
        url
        alt
      }
      seo {
        title
        description
      }
    }
    allDatoCmsSkill(sort: { fields: position, order: ASC }) {
      edges {
        node {
          name
          slug
        }
      }
    }
    allDatoCmsLawyer(sort: { fields: position, order: ASC }) {
      edges {
        node {
          name
          slug
          jobTitle
          photo {
            alt
            url
          }
          linkedinLink
        }
      }
    }
  }
`;

export default IndexPage;
